import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../Utils/API.js';
import { AgentInternal } from '../Types/Agent';
import { reactSelectItemToTag, Tag, tagToReactSelectItem } from '../Types/Tag';
import StepsNav from '../Components/Steps';
import Select from 'react-select';
import { RheostatPricingGraph } from '../Components/RheostatSlider';

type Props =
  { agent?: AgentInternal
  , allTags: Tag[]
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

enum Steps
  { Intro
  , Skills
  , Budget
  , FinalAnimation
  }

const PreferenceWizard = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Steps.Intro);

  const [selectedTags, setSelectedTags] = useState(
    props.agent?.tags.map((tag) => tagToReactSelectItem(tag)) ?? []
  );

  const [budgetLow, setBudgetLow] = useState(props.agent?.budgetCentsLow ?? 0);
  const [budgetHigh, setBudgetHigh] = useState(props.agent?.budgetCentsHigh ?? 100_00);

  useEffect(() => {
    if (currentStep === Steps.FinalAnimation) {
      // 0.5 seconds after arriving on the FinalAnimation step, call the submitPreferences function.
      setTimeout(submitPreferences, 500);
    } else {

    }
  }, [currentStep])

  function submitPreferences() {
    const tags: Tag[] = selectedTags.map((item) => (reactSelectItemToTag(item, props.allTags)));

    const body = {
      tags: tags,
      budgetLow: budgetLow,
      budgetHigh: budgetHigh
    }

    API.post("agent_set_copilot_preferences_path", body).then(function (result) {
      if (result['error']) {
      } else {
        window.location.href = result['path']
      }
    })
  }

  const ViewIntroPage = () => (
    <div className="">
      <h2 className="">Ready to find your perfect CoPilot?</h2>
      <div className="fs-xl">We’ll match you with top talent that fits your needs and your budget.</div>
      <button className="mt-10 btn btn-primary px-15 t--intro-next" onClick={() => setCurrentStep(Steps.Skills)}>
        Continue
      </button>
    </div>
  )

  const ViewSkillsPage = () => (
    <div className="">
      <h2 className="">What are the main skills required for your work?</h2>
      <div className="fs-xl">For the best results, add 3-5 skills</div>
      <Select
        className="rounded mt-2 text-start t--tag-select"
        classNames={{
          control: (state) => 'form-control p-1 rounded-2',
          multiValue: (state) => 'rounded-1',
          dropdownIndicator: (state) => 'py-0'
        }}
        isMulti={true}
        options={props.allTags.map((tag) => tagToReactSelectItem(tag))}
        onChange={(newTags: ReactSelectOption[]) => setSelectedTags(newTags)}
        value={selectedTags}
        placeholder="Select from the dropdown or type to search…"
      />
      <button className="mt-10 btn btn-primary px-15 t--skills-next" onClick={() => setCurrentStep(Steps.Budget)}>
        Continue
      </button>
    </div>
  )

  function handleRheostatChange(lowValue: number, highValue: number) {
    setBudgetLow(lowValue * 100);
    setBudgetHigh(highValue * 100);
  }

  const ViewBudgetPage = () => (
    <div className="">
      <h2 className="">Tell us about your budget</h2>
      <div className="fs-xl">This will help us match you to talent within your range.</div>
      <div className="py-5">
        <RheostatPricingGraph
          minValue={0}
          maxValue={100}
          stepValue={5}
          presetLowValue={budgetLow / 100}
          presetHighValue={budgetHigh / 100}
          onValuesChange={handleRheostatChange}
        />
        <div className="mt-4 d-flex justify-content-between">
          <div className="">
            <div className="fs-md fw-bold mb-1 text-start">Minimum</div>
            <div className="input-group align-items-center p-0 bg-white">
              <div className="input-group-prepend ms-2">$</div>
              <input
                className="form-control fw-semibold fs-lg t--budget-low" type="number"
                value={budgetLow / 100}
                min={0} max={5000}
                onChange={(event) => setBudgetLow(parseInt(event.target.value) * 100)}
              />
              <div className="input-group-append me-2">/hr</div>
            </div>
          </div>
          <div className="">
            <div className="fs-md fw-bold mb-1 text-start">Maximum</div>
            <div className="input-group align-items-center p-0 bg-white">
              <div className="input-group-prepend ms-2">$</div>
              <input
                className="form-control fw-semibold fs-lg t--budget-high" type="number"
                value={budgetHigh / 100}
                min={0} max={5000}
                onChange={(event) => setBudgetHigh(parseInt(event.target.value) * 100)}
              />
              <div className="input-group-append me-2">/hr</div>
            </div>
          </div>
        </div>
      </div>
      <button className="mt-10 btn btn-primary px-15 t--budget-next" onClick={() => setCurrentStep(Steps.FinalAnimation)}>
        Continue
      </button>
    </div>
  )

  const ViewFinalAnimationPage = () => (
    <div className="py-15">
      <img src='/img/dashboard/binoculars.gif' width={96} />
      <div className="fs-xl text-gray-700 mt-1">Looking for the right talent…</div>
    </div>
  )

  return (
    <div className="row mt-3 justify-content-center">
      <div className="col-lg-8 col-xl-6 text-center">
        <StepsNav numSteps={3} currentStepNum={currentStep} containerClass="mb-4"/>
        {(() => {
          switch (currentStep) {
            case Steps.Intro:
              return ViewIntroPage();
            case Steps.Skills:
              return ViewSkillsPage();
            case Steps.Budget:
              return ViewBudgetPage();
            case Steps.FinalAnimation:
              return ViewFinalAnimationPage();
            default:
              return null;
          }
        })() as React.ReactNode}
      </div>
    </div>
  )
}

export default PreferenceWizard;
