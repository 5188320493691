import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API.js';
import { Copilot, copilotProfileUrl } from '../../Types/Copilot';
import { lastSeenAtDescription } from '../../Utils/DateTime'
import { htmlIf, maybeHtml, formatMultiParagraphString } from '../../Utils/HTML';
import { Tag } from '../../Types/Tag';
import Perspective from '../../Types/Perspective';
import * as CopilotProfile from '../../Common/CopilotProfile';
import { DismissibleModal } from '../../Components/Modal';
import { CurrencyCode, ExchangeRate, formatExchangeCurrencyWithoutCents } from '../../Utils/Currency';

type Props =
  { copilot: Copilot
  , cardButtons: ButtonData[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate

  // featuredTags allows us to pass an array of tags that are most important in the current context
  // (i.e. tags that a user added to a potential request). If the CoPilot in question has any of these featured tags,
  // they will be prioritized in the display of tags on the CoPilot's card.
  , featuredTags: Tag[]
  // allowedTags allows us to pass an (optional) array of tags that are allowed to be displayed on the CoPilot card.
  // If allowedTags is set, the set of possible tags we could display on a CoPilot card is limited to only the tags
  // included in the allowedTags array.
  // This is primarily used to only show relevant Agent tags (depending on the user's perspective).
  , allowedTags?: Tag[]
  // If set, displays text on a badge in the upper right hand corner of the card
  , badgeText?: string
  // If set to true, the card will be clickable (and when clicked, will link to the CoPilot's profile)
  , clickToViewProfile?: boolean
  // If set to true, the card (and any buttons clicked) will open links in a new tab/window.
  , targetBlank?: boolean
  }

export type ButtonData =
  { btnClass: string
  , btnLabel: string
  , onClick: (event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) => void
  , isViewProfileModalButton?: boolean
  }

/**
 * This is a click handler that doesn't do anything, meant to supplied as the `onClick` property in `CopilotCard.ButtonData`
 * when `isViewProfileModalButton` is TRUE.
 *
 * The click handler for the "View Profile" button is already defined and handled
 * in the CopilotCard component itself (it brings up a modal of the Copilot profile).
 */
export function emptyClickHandler(event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot): void {}

const expertCopilotIds = [15, 17, 22, 37, 41, 44, 52, 58, 95, 140];

export const CopilotCard = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileProps, setProfileProps] = useState<CopilotProfile.Props>();

  function handleViewProfileModalClicked() {
    const body = { copilotIndividualId: props.copilot.id, perspective: Perspective.ViewOnly }
    API.post("agent_fetch_copilot_profile_props_path", body).then(function (result) {
      setProfileProps(result);
      setShowProfileModal(true);
    })
  }

  const ViewTags = () => {
    let allowedCopilotTags = props.copilot.tags;
    if (props.allowedTags !== null && props.allowedTags !== undefined) {
      allowedCopilotTags = props.copilot.tags.filter(copilotTag =>
        props.allowedTags.some(allowedTag => allowedTag.id === copilotTag.id)
      )
    }

    const commonTags = allowedCopilotTags.filter(copilotTag =>
      props.featuredTags.some(featuredTag => featuredTag.id === copilotTag.id)
    );

    const firstCoupleTags = allowedCopilotTags.slice(0, 7)

    const numTagsRemaining =
      commonTags.length > 0
      ? allowedCopilotTags.length - commonTags.length
      : allowedCopilotTags.length - firstCoupleTags.length

    const tagsToDisplay = commonTags.length > 0 ? commonTags : firstCoupleTags

    return (
      <>
        {tagsToDisplay.map((tag) => (
          <div key={`Tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
        ))}
        {htmlIf(numTagsRemaining > 0,
          <div key='more-tags' className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">+{numTagsRemaining} skills</div>
        )}
      </>
    )
  }

  const ViewCard = () => (
    <div className="card position-relative rounded-1 h-100">
      <div className="card-img-top bg-accent" style={{height: 81, borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
        {maybeHtml(props.badgeText, (badgeText) => (
          <div className="badge bg-dark fw-bold position-absolute" style={{ top: '28px', right: '28px' }}>
            {badgeText}
          </div>
        ))}
      </div>
      <div className="card-body p-3 d-flex flex-column justify-content-start">
        <div className="avatar avatar-xxl d-block mb-2 mt-n11">
          <img className="avatar-img rounded-circle border border-4 border-white" src={props.copilot.imageUrl} loading='lazy'/>
        </div>
        <div className="d-flex justify-content-between mb-2 text-dark">
          <div className="fs-xl fw-bold">{props.copilot.preferredName}</div>
          {htmlIf((props.copilot.averageRating !== null && props.copilot.numRatings > 0),
            <div className="text-dark d-flex align-items-center">
              <i className="ai-star me-1"/>
              {props.copilot.averageRating?.toFixed(1)}
            </div>
          )}
        </div>
        {maybeHtml(props.copilot.location, (location) => (
          <div className="d-flex align-items-center pb-1 text-dark">
            <i className="ai-map-pin me-1 fs-xl" />
            {location}
          </div>
        ))}
        {htmlIf((props.copilot.hourlyRateCents !== null && props.copilot.hourlyRateCents > 0),
          <div className="d-flex align-items-center pb-1 text-dark">
            <i className="ai-card me-1 fs-xl" />
            {formatExchangeCurrencyWithoutCents(props.displayCurrency, props.copilot.hourlyRateCents, props.exchangeRates)}/hour
          </div>
        )}
        <div className="overflow-auto">
          <ViewTags />
        </div>
        {htmlIf(props.copilot.lastSeenAt,
          <div className="mt-1 fs-xs">{lastSeenAtDescription(props.copilot.lastSeenAt)}
          </div>
        )}
        <div className="d-flex flex-column pt-2 mt-auto">
          {props.cardButtons?.map((buttonData) => (
            <button
              key={buttonData.btnLabel}
              className={buttonData.btnClass}
              onClick={
                buttonData.isViewProfileModalButton
                ? handleViewProfileModalClicked
                : (event: React.MouseEvent<HTMLButtonElement>) => buttonData.onClick(event, props.copilot)
              }
            >
              {buttonData.btnLabel}
            </button>
          ))}
        </div>
      </div>
    </div>
  )

  return (
    <>
      {maybeHtml(profileProps, (profProps) =>
        <>
          {htmlIf(showProfileModal,
            <DismissibleModal
              dialogClass="modal-xl"
              bodyClass=""
              body={<div className="mt-1">{CopilotProfile.CopilotProfile(profProps)}</div>}
              onDismiss={() => setShowProfileModal(false)}
            />
          )}
        </>
      )}
      { props.clickToViewProfile
      ? <a className={`text-decoration-none t--copilot-id-${props.copilot.id}`}
          href={copilotProfileUrl(props.copilot)}
          target={props.targetBlank ? '_blank' : null}
        >
          <ViewCard />
        </a>
      : <ViewCard />
      }
    </>
  )
}
