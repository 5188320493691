import * as React from 'react';
import { useState } from 'react';
import { HourlyContract, HourlyWorkItem, WorkItemStatus, HourlyPayment } from '../Types/Hourly';
import Perspective from '../Types/Perspective';
import { htmlIf } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
}

function upgradeMembershipPath(perspective: Perspective) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/account/billing`
  }
}

export const HourlyReceipts = (contract: HourlyContract, perspective: Perspective): React.ReactElement => {
  const [numReceiptsToDisplay, setNumReceiptsToDisplay] = useState(10);
  const [expandedPayments, setExpandedPayments] = useState([]);

  const ViewStatusBadge = (payment: HourlyPayment) => {
    if (payment.refundedAt) {
      return <span className="badge bg-faded-danger text-danger fw-bold fs-xs">Refunded</span>
    } else if (payment.transferredToCopilotAt) {
      return <span className="badge bg-faded-success text-success fw-bold fs-xs">Paid</span>
    } else if (payment.paidAt) {
      switch (perspective) {
        case Perspective.Agent:
          return <span className="badge bg-faded-success text-success fw-bold fs-xs">Paid</span>
        case Perspective.Copilot:
          return <span className="badge bg-faded-info text-dark fs-xs">Pending</span>
      }
    } else {
      return <span className="badge bg-faded-info text-dark fs-xs">Pending</span>
    }
  }

  function showMoreReceiptsClickHandler() {
    setNumReceiptsToDisplay(numReceiptsToDisplay + 10);
  }

  const toggleExpansion = (id) => {
    setExpandedPayments((prevExpandedPayments) =>
      prevExpandedPayments.includes(id)
        ? prevExpandedPayments.filter((paymentId) => paymentId !== id)
        : [...prevExpandedPayments, id]
    );
  };

  const ViewPaymentCard = (payment: HourlyPayment): React.ReactElement => {
    const isExpanded = expandedPayments.includes(payment.id);

    return (
      <div className="card rounded-1 cursor-pointer" onClick={() => toggleExpansion(payment.id)}>
        <div className="card-body p-3 row">
          <div className="col d-flex align-items-center">
            <span className="fs-md fw-semibold text-gray-900 me-1">{payment.timeRangeDescription}</span>
            {ViewStatusBadge(payment)}
          </div>
          <div className="col-auto text-end">
            <i className={"text-gray-900 fs-xl ai-chevron-" + (isExpanded ? "up" : "down")} />
          </div>
          {htmlIf(isExpanded,
            <div>
              {htmlIf(perspective === Perspective.Agent,
                <>
                  <div className="row mt-2">
                    <div className="col">Subtotal ({payment.totalDurationDescription})</div>
                    <div className="col-auto text-end">{formatUSDWithCents(payment.subtotalAmountCents)}</div>
                  </div>
                  <div className="row mt-1 pb-1 border-bottom">
                    <div className="col">Service Fee</div>
                    <div className="col-auto text-end">{formatUSDWithCents(payment.platformFeeCents)}</div>
                  </div>
                  <div className="row mt-1 fw-bold">
                    <div className="col">Total</div>
                    <div className="col-auto text-end">{formatUSDWithCents(payment.subtotalAmountCents + payment.platformFeeCents)}</div>
                  </div>
                </>
              )}
              {htmlIf(perspective === Perspective.Copilot,
                <div className="row mt-2">
                  <div className="col">Payment ({payment.totalDurationDescription})</div>
                  <div className="col-auto text-end fw-bold">{formatUSDWithCents(payment.copilotAmountDueCents)}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <h5>Payments</h5>
      {htmlIf(contract.customerHasActiveSubscription === false && perspective == Perspective.Agent,
        <div className="my-2 p-2 bg-faded-info rounded-1 text-gray-900 d-flex align-items-center">
          Want to save 50% on your service fees?
          <a href={upgradeMembershipPath(perspective)} className="btn btn-link text-info fs-md p-0 py-1 ms-1">Upgrade now</a>
        </div>
      )}
      {contract.payments
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .slice(0, numReceiptsToDisplay)
        .map((payment, index) => (
          <div className={`mb-2 t--hourly-payment-id-${payment.id}`} key={index}>
            {ViewPaymentCard(payment)}
          </div>
      ))}
      {htmlIf(numReceiptsToDisplay < contract.payments.length,
        <button className="w-100 btn btn-link py-0 mt-n2" onClick={showMoreReceiptsClickHandler}>Show more</button>
      )}
    </div>
  )
}

export default HourlyReceipts;
