import * as React from 'react';
import Select from 'react-select';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API';
import { Copilot, CopilotWorkStyle } from '../../Types/Copilot';
import { Tag, tagToReactSelectItem, reactSelectItemToTag } from '../../Types/Tag';
import { htmlIf, formatMultiParagraphString } from '../../Utils/HTML';
import * as Language from '../../Utils/Language';
import { formatUSDWithCents } from '../../Utils/Currency';
import { StandalonePricingGraph } from '../../Components/RheostatSlider';

type Props =
  { individual: Copilot
  , allTags: Tag[]
  , allWorkStyles: CopilotWorkStyle[]
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

const copilotPaymentPercentage = 0.8

const AboutMe = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const [preferredName, setPreferredName] = useState(props.individual.preferredName);
  const [pronouns, setPronouns] = useState(props.individual.pronouns);
  const [hourlyRate, setHourlyRate] = useState(props.individual.hourlyRateCents / 100)
  const [bio, setBio] = useState(props.individual.bio);
  const [selectedTagItems, setSelectedTagItems] = useState(props.individual.tags.map((tag) => (tagToReactSelectItem(tag))));
  const [selectedWorkStyleItems, setSelectedWorkStyleItems] = useState(
    props.individual.workStyles.map(workStyle => ({ value: workStyle.id, label: workStyle.name }))
  );
  const [selectedLanguages, setSelectedLanguages] = useState(props.individual.languageCodes.map((code) => Language.asSelectOption(code)));
  const [isOpenToWork, setIsOpenToWork] = useState(props.individual.isOpenToWork);
  const [location, setLocation] = useState(props.individual.location);

  function saveAboutMe() {
    const tags: Tag[] = selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));
    const workStyles: CopilotWorkStyle[] = selectedWorkStyleItems.map((item) => ({ id: item.value, name: item.label }))

    const postBody = {
      preferredName: preferredName,
      pronouns: pronouns,
      hourlyRateCents: (Number(hourlyRate) * 100),
      bio: bio,
      tags: tags,
      workStyles: workStyles,
      isOpenToWork: isOpenToWork,
      location: location,
      languageCodes: selectedLanguages.map((languageItem: ReactSelectOption) => languageItem.value)
    }

    API.post("copilot_account_update_profile_info_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">About Me</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6">
            <h5 className="mb-1">Preferred Name</h5>
            { isEditing
            ? <input
                onChange={(e) => setPreferredName(e.target.value)}
                className="form-control"
                placeholder='Enter your name as it should be displayed to other users…'
                value={preferredName}
              />
            : <div>{preferredName}</div>
            }
          </div>
          <div className="col-lg-6 mt-2 mt-lg-0">
            <h5 className="mb-1">Pronouns</h5>
            { isEditing
            ? <input
                onChange={(e) => setPronouns(e.target.value)}
                className="form-control"
                value={pronouns}
              />
            : <div>{pronouns}</div>
            }
          </div>
          <div className="mt-2">
            <div className="d-flex align-items-center mb-1">
              <h5 className="mb-0">Hourly Rate</h5>
              <div className="fs-sm ms-2 fst-italic">This is the default hourly rate that customers will pay when working with you.</div>
            </div>
            { isEditing
            ? <div className="row">
                <div className="col-12 d-flex justify-content-center mb-2 py-1 bg-gray rounded-3">
                  <div className="col-md-9 text-center">
                    <div className="fw-semibold text-decoration-underline">Distribution of hourly rates among Lucia CoPilots</div>
                    <div className="fs-md text-gray-700 mb-1">Lucia average hourly rate: $35</div>
                    <StandalonePricingGraph
                      topBorderRadius={6}
                      barCategoryGapPercentage={5}
                      heightPx={75}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6 className="fw-bold mb-1">Your public hourly rate</h6>
                  <div className="input-group align-items-center p-0">
                    <div className="input-group-prepend ms-2">$</div>
                    <input
                      className="form-control t--hourly-rate" type="number"
                      value={hourlyRate} min={0} max={5000}
                      onChange={(event) => setHourlyRate(parseInt(event.target.value))}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2 mt-md-0">
                  <h6 className="fw-bold mb-1">Your take-home rate</h6>
                  <input
                    className="form-control"
                    value={hourlyRate ? formatUSDWithCents(hourlyRate * copilotPaymentPercentage * 100) : '$0'}
                    disabled={true}
                  />
                  {/* </div> */}
                </div>
              </div>
            : <div>${hourlyRate}</div>
            }
          </div>
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Bio (Pitch yourself!)</h5>
          { isEditing
          ? <textarea
              onChange={(e) => setBio(e.target.value)}
              className="form-control"
              rows={12}
              placeholder='Enter your bio…'
              maxLength={5000}
              value={bio}
            />
          : <div>{formatMultiParagraphString(bio)}</div>
          }
        </div>
        <div className="mt-2">
            <h5 className="mb-1">Location</h5>
            { isEditing
            ? <input
                onChange={(e) => setLocation(e.target.value)}
                className="form-control"
                value={location}
              />
            : <div>{location}</div>
            }
          </div>
        <div className="mt-2">
          <h5 className="mb-1">Tags</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.map((tag) => (tagToReactSelectItem(tag)))}
              onChange={(newTags: ReactSelectOption[]) => setSelectedTagItems(newTags)}
              value={selectedTagItems}
            />
          : <div>
              {selectedTagItems.map((tag) => (
                <div key={tag.label} className="badge bg-accent text-dark me-1 d-inline-block">{tag.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">What types of work are you open to?</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allWorkStyles.map((workStyle) => ({ label: workStyle.name, value: workStyle.id}))}
              onChange={(newWorkStyles: ReactSelectOption[]) => setSelectedWorkStyleItems(newWorkStyles)}
              value={selectedWorkStyleItems}
            />
          : <div>
              {selectedWorkStyleItems.map((workStyle) => (
                <div key={workStyle.label} className="badge bg-accent text-dark me-1 d-inline-block">{workStyle.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Languages</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={Language.allAsSelectOptions()}
              onChange={(newLanguages: ReactSelectOption[]) => setSelectedLanguages(newLanguages)}
              value={selectedLanguages}
              placeholder="Select any languages you speak fluently…"
            />
          : <div>
              {selectedLanguages.map((languageItem) => (
                <div key={languageItem.value} className="badge bg-accent text-dark me-1 d-inline-block">{languageItem.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-6 d-flex align-items-center">
          <div className="mt-1 form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              disabled={!isEditing}
              checked={isOpenToWork}
              onChange={() => setIsOpenToWork(!isOpenToWork)}
            />
          </div>
          <div className="ms-2">
            <h5 className="mb-1">Profile Visibility</h5>
            <div className="fs-md">
              { isOpenToWork
              ? "Your profile is currently visible to agents and new work can be submitted to you."
              : "Your profile is hidden from agents — you will not receive email notifications or direct requests for new work. An 'Out of Office' status will be displayed for anyone you are currently working with."
              }
            </div>
          </div>
        </div>
        {htmlIf(isEditing,
          <button onClick={saveAboutMe} className="btn btn-primary mt-3 px-3">Save</button>
        )}
      </div>
    </div>
  )
}

export default AboutMe;
