import * as React from 'react';

type Props =
  { numSteps: number
  , currentStepNum: number
  , containerClass?: string
  }

const StepsNav = (props: Props) => {
  return (
    <div className={`d-flex ${props.containerClass}`}>
      {[...Array(props.numSteps)].map((step, index) => (
        <div
          className={`rounded w-100 me-2 ${index < props.currentStepNum + 1 ? 'bg-primary' : 'bg-gray-300'}`}
          style={{height: 8}}>
        </div>
      ))}
    </div>
  )
}

export default StepsNav;
