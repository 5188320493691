import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Rheostat from "rheostat";
import "rheostat/initialize";
import { BarChart, Bar, ResponsiveContainer } from 'recharts';
import { htmlIf } from '../Utils/HTML';

type Props = {
  /** The minimum value on the slider. */
  minValue: number;

  /** The maximum value on the slider. */
  maxValue: number;

  /** If set, the starting low value on the slider. Defaults to the minValue. */
  presetLowValue?: number;

  /** If set, the starting high value on the slider. Defaults to the maxValue. */
  presetHighValue?: number;

  /** Callback function for when the slider values are changed. */
  onValuesChange?: ((lowValue: number, highValue: number) => void);

  /** (Helpful when implementing) If set to true, shows the lowValue and highValue below the slider. */
  showValuesText?: boolean;

  /** If set, determines the step value of the slider. Defaults to 1. */
  stepValue?: number;
};

export const RheostatSlider = (props: Props) => {
  const [lowValue, setLowValue] = useState(props.presetLowValue ?? props.minValue);
  const [highValue, setHighValue] = useState(props.presetHighValue ?? props.maxValue);

  const stepValue = props.stepValue ?? 1;

  const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

  // Sync state with props when `presetLowValue` or `presetHighValue` change
  useEffect(() => {
    setLowValue(clamp(props.presetLowValue ?? props.minValue, props.minValue, props.maxValue - 1));
    setHighValue(clamp(props.presetHighValue ?? props.maxValue, props.minValue, props.maxValue));
  }, [props.presetLowValue, props.presetHighValue, props.minValue, props.maxValue]);

  return (
    <div>
      <Rheostat
        min={props.minValue / stepValue}
        max={props.maxValue / stepValue}
        values={[lowValue / stepValue, highValue / stepValue]}

        onValuesUpdated={(e) => {
          setLowValue(e.values[0] * stepValue);
          setHighValue(e.values[1] * stepValue);

          props.onValuesChange?.(e.values[0] * stepValue, e.values[1] * stepValue);
       }}
      />
      {htmlIf(props.showValuesText === true,
        <>
          <div>Low: {lowValue}</div>
          <div>High: {highValue}</div>
        </>
      )}
    </div>
  )
}

/** Important: Expects minimum and maximum values of 0 & 100 and a step value of 5, otherwise the graph will look funky */
export const RheostatPricingGraph = (props: Props) => {
  // Allows us to dynamically set the color of a bar, based on the current values on the slider.
  // Assumes we have 20 bars and the range we're looking at is 0–100.
  const barFillColor = (barNum: number) => {
    const barValue = barNum * 5;

    // If the barValue is outside the range of low and high values, it should be gray
    if (barValue <= props.presetLowValue || barValue > props.presetHighValue) {
      return '#d8d8d8';
    }

    // Otherwise, it should be blue
    return '#0A66C2';
  };

  // Frequency of hourly rates occuring in each bucket (first bucket is $0-5, next is $5-10, etc., all the way to $95-100)
  // Data last pulled on 12/22/24.
  const chartData = [
    { value: 3, fill: barFillColor(1) },
    { value: 1, fill: barFillColor(2) },
    { value: 15, fill: barFillColor(3) },
    { value: 32, fill: barFillColor(4) },
    { value: 100, fill: barFillColor(5) },
    { value: 174, fill: barFillColor(6) },
    { value: 212, fill: barFillColor(7) },
    { value: 212, fill: barFillColor(8) },
    { value: 37, fill: barFillColor(9) },
    { value: 25, fill: barFillColor(10) },
    { value: 26, fill: barFillColor(11) },
    { value: 1, fill: barFillColor(12) },
    { value: 6, fill: barFillColor(13) },
    { value: 2, fill: barFillColor(14) },
    { value: 0, fill: barFillColor(15) },
    { value: 19, fill: barFillColor(16) },
    { value: 2, fill: barFillColor(17) },
    { value: 0, fill: barFillColor(18) },
    { value: 2, fill: barFillColor(19) },
    { value: 1, fill: barFillColor(20) }
  ];

  return (
    <div>
      <div className="mb-n2" style={{ width: '100%', height: '100px' }}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            barCategoryGap="10%"
          >
            <Bar
              dataKey="value"
              fill='#0A66C2'
              radius={[8, 8, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {RheostatSlider(props)}
    </div>
  )
}

type StandalonePricingGraphProps =
  { topBorderRadius: number
  , barCategoryGapPercentage: number
  , heightPx: number
  }

export const StandalonePricingGraph = (props: StandalonePricingGraphProps) => {
  // Frequency of hourly rates occurring in each bucket (first bucket is $0-5, next is $5-10, etc., all the way to $95-100)
  // Data last pulled on 12/22/24.
  const chartData = [
    { value: 3, tooltip: '$0-5' },
    { value: 1, tooltip: '$5-10' },
    { value: 15, tooltip: '$10-15' },
    { value: 32, tooltip: '$15-20' },
    { value: 100, tooltip: '$20-25' },
    { value: 174, tooltip: '$25-30' },
    { value: 212, tooltip: '$30-35' },
    { value: 212, tooltip: '$35-40' },
    { value: 37, tooltip: '$40-45' },
    { value: 25, tooltip: '$45-50' },
    { value: 26, tooltip: '$50-55' },
    { value: 1, tooltip: '$55-60' },
    { value: 6, tooltip: '$60-65' },
    { value: 2, tooltip: '$65-70' },
    { value: 0, tooltip: '$70-75' },
    { value: 19, tooltip: '$75-80' },
    { value: 2, tooltip: '$80-85' },
    { value: 0, tooltip: '$85-90' },
    { value: 2, tooltip: '$90-95' },
    { value: 1, tooltip: '$95-100' }
  ];

  return (
    <>
      <div className="border-bottom border-primary" style={{ width: '100%', height: `${props.heightPx}px` }}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            barCategoryGap={`${props.barCategoryGapPercentage}%`}
          >
            <Bar
              dataKey="value"
              fill="#0A66C2"
              radius={[props.topBorderRadius, props.topBorderRadius, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="d-flex justify-content-between">
        <span>$0</span>
        <span>$100</span>
      </div>
    </>
  );
};
