import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { ProjectRequest, RequestStatus, RequestOfferStatus, ProjectRequestOffer, displayRequestBudgetRangeUSD } from '../Types/ProjectRequest';
import { RequestType } from '../Types/RequestCard';
import { CustomerRequestStatusBadge } from '../Common/RequestStatusBadge';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml, formatMultiParagraphString } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';
import RequestDescription from '../Common/RequestDescription';
import { copilotProfileUrl, copilotMessagesUrl, Copilot } from '../Types/Copilot';
import Perspective from '../Types/Perspective';
import { FiveStarRating, RatingModalButton, ViewSubmittedRating } from './Components/Ratings';
import { displayScope } from '../Types/RequestScope';

type Props =
  { request: ProjectRequest
  , negotiationEnabled: boolean
  , perspective: Perspective
  }

enum Modal
  { NoModal
  , CancelRequestModal
  , ReassignRequestModal
  , SuccessfulReassignToPoolModal
  , ApproveWorkModal
  , AcceptOfferModal
  , DeclineOfferModal
  }

function messagesPath(perspective: Perspective, copilot?: Copilot) {
  switch(perspective) {
    case Perspective.Agent:
      return copilot ? `/agent/messages?other_user_id=${copilot.userId}` : '/agent/messages'
  }
}

function requestsPath(perspective: Perspective) {
  switch(perspective) {
    case Perspective.Agent:
      return '/agent/requests'
  }
}

function reassignRequestToCopilotPath(perspective: Perspective, request: ProjectRequest) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/requests/reassign/p/${request.id}`
  }
}

function editRequestPath(perspective: Perspective, request: ProjectRequest) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/requests/edit/p/${request.id}`
  }
}

function upgradeMembershipPath(perspective: Perspective) {
  switch(perspective) {
    case Perspective.Agent:
      return `/agent/account/billing`
  }
}

const RequestDetails = (props: Props) => {
  const [request, setRequest] = useState(props.request);
  const [modal, setModal] = useState(Modal.NoModal);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [requestRating, setRequestRating] = useState(props.request.customerRating?.rating);
  const [requestRatingComment, setRequestRatingComment] = useState(props.request.customerRating?.ratingComment);
  const [ratingCompletedOnPageLoad, setRatingCompletedOnPageLoad] = useState(props.request.customerRating !== null);

  const [selectedOffer, setSelectedOffer] = useState<ProjectRequestOffer>();

  function cancelRequest(request: ProjectRequest) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.PROJECT_REQUEST, requestId: request.id }
    API.post("api_requests_withdraw_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function completeRequest(request: ProjectRequest) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.PROJECT_REQUEST, requestId: request.id }
    API.post("api_requests_complete_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function acceptRequestOffer(offer: ProjectRequestOffer) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.PROJECT_REQUEST, offerId: offer.id }
    API.post("api_requests_accept_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
        setModal(Modal.NoModal)
        setIsSubmitting(false);
      }
    })
  }

  function declineRequestOffer(offer: ProjectRequestOffer) {
    setIsSubmitting(true);
    const body = { requestType: RequestType.PROJECT_REQUEST, offerId: offer.id }
    API.post("api_requests_decline_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
        setModal(Modal.NoModal)
        setIsSubmitting(false);
      }
    })
  }

  function reassignRequestToPool(request: ProjectRequest) {
    const body = { requestId: request.id, requestType: RequestType.PROJECT_REQUEST }
    API.post("api_requests_reassign_request_path", body).then(function (result) {
      if (result['error']) {
        setModal(Modal.NoModal);
      } else {
        setRequest(result['request']);
        setModal(Modal.SuccessfulReassignToPoolModal);
      }
    })
  }

  function reassignRequestToCopilot(request: ProjectRequest) {
    window.location.href = reassignRequestToCopilotPath(props.perspective, request);
    setModal(Modal.NoModal);
  }

  function formatDate(dateString: string):string {
    const date = new Date(dateString)
    return (
      date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    )
  }

  const CancelRequestButton = () => (
    <button className="btn btn-outline-danger px-3 t--cancel-request" onClick={() => setModal(Modal.CancelRequestModal)}>
      Cancel Request
    </button>
  )

  const ReassignRequestButton = () => (
    <button className="btn btn-primary me-1 px-3 t--reassign-request" onClick={() => setModal(Modal.ReassignRequestModal)}>
      Reassign Request
    </button>
  )

  const ViewMessagesButton = () => (
    <a className="btn btn-outline-primary px-3 t--view-messages" href={copilotMessagesUrl(request.copilot)}>
      Messages
    </a>
  )

  const EditRequestButton = () => (
    <a className="btn btn-outline-primary px-3 me-1 t--edit-request" href={editRequestPath(props.perspective, request)}>
      Edit Request
    </a>
  )

  const ApproveWorkButton = () => (
    <button className="btn btn-primary me-1 px-3 t--approve-work" onClick={() => setModal(Modal.ApproveWorkModal)}>
      Approve Work
    </button>
  )

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.CancelRequestModal:
        return (
          <DismissibleModal
            title={<h4>Cancel this request?</h4>}
            body={
              <div>
                Are you sure you want to cancel this request?
                <div className="mt-2">
                  The hold placed on your payment method will be cancelled and this request will no longer be available to CoPilots.
                </div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2 t--confirm-cancel-request"
                  onClick={() => cancelRequest(request)}
                  disabled={isSubmitting}
                >
                  Yes, cancel it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ReassignRequestModal:
        return (
          <DismissibleModal
            title={<h4>Reassign this request</h4>}
            body={
              <div>
                The CoPilot you originally selected is unavailable or has declined the request. What would you like to do?
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 mb-2 t--request-another-copilot" onClick={() => reassignRequestToCopilot(request)}>
                  Request another CoPilot
                </button>
                <button className="btn btn-primary w-100 t--confirm-reassign-to-pool" onClick={() => reassignRequestToPool(request)}>
                  Open request to all CoPilots
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.SuccessfulReassignToPoolModal:
        return (
          <DismissibleModal
            title={<h4>Request successfully reassigned!</h4>}
            body={<div>Your request has been opened to all CoPilots. You will receive an email when it is claimed!</div>}
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
        case Modal.ApproveWorkModal:
          return (
            <DismissibleModal
              title={<h4>Approve work and close request?</h4>}
              body={
                <>
                  <div className="fw-semibold mb-1 text-gray-900">Are you ready to approve your CoPilot’s work and close this request?</div>
                  <div>
                    Payment will be released to your CoPilot immediately.
                  </div>
                </>
              }
              footer={
                <>
                  <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                    onClick={() => setModal(Modal.NoModal)}
                    disabled={isSubmitting}
                  >
                    No, not yet
                  </button>
                  <button className="btn btn-success w-100 w-sm-auto ms-sm-2 t--confirm-approve-work"
                    onClick={() => completeRequest(request)}
                    disabled={isSubmitting}
                  >
                    Yes, approve & close
                  </button>
                </>
              }
              onDismiss={() => setModal(Modal.NoModal)}
            />
          )
      case Modal.AcceptOfferModal:
        return (
          <DismissibleModal
            title={<h4>Accept offer</h4>}
            body={<div>Are you sure that you want to accept the offer from {selectedOffer.copilot.preferredName}?</div>}
            footer={
              <>
                <button className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0 t--confirm-accept-offer"
                  onClick={() => acceptRequestOffer(selectedOffer)}
                  disabled={isSubmitting}
                >
                  Yes, accept offer
                </button>
                <button className="btn btn-outline-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.DeclineOfferModal:
        return (
          <DismissibleModal
            title={<h4>Decline offer</h4>}
            body={<div>Are you sure that you want to decline the offer from {selectedOffer.copilot.preferredName}?</div>}
            footer={
              <>
                <button className="btn btn-danger w-100 w-sm-auto mb-2 mb-sm-0 t--confirm-decline-offer"
                  onClick={() => declineRequestOffer(selectedOffer)}
                  disabled={isSubmitting}
                >
                  Yes, decline offer
                </button>
                <button className="btn btn-outline-primary w-100 w-sm-auto ms-sm-2"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      default:
        return null
    }
  }

  const ViewRequestHeader = () => (
    <div className="p-3 border-bottom">
      <div className="">
        <div className="row align-items-center">
          <div className="col-lg d-lg-flex align-items-center">
            <h3 className="mb-0 me-1">{request.title}</h3>
            <CustomerRequestStatusBadge status={request.status}/>
          </div>
          <div className="col-auto d-flex justify-content-end mt-1 mt-lg-0">
            {htmlIf(request.status === RequestStatus.DECLINED_PENDING,
              <ReassignRequestButton />
            )}
            {htmlIf(request.status === RequestStatus.SUBMITTED,
              <EditRequestButton />
            )}
            {htmlIf([RequestStatus.SUBMITTED, RequestStatus.DECLINED_PENDING].includes(request.status),
              <CancelRequestButton />
            )}
            {htmlIf(request.status === RequestStatus.UNDER_REVIEW,
              <ApproveWorkButton />
            )}
            {htmlIf([RequestStatus.CLAIMED, RequestStatus.UNDER_REVIEW, RequestStatus.COMPLETED].includes(request.status),
              <ViewMessagesButton />
            )}
          </div>
        </div>
        <div className="mt-1">
          <div className="fs-sm">
            Submitted
            <span className=""> {formatDate(request.createdAt)}</span>
            {htmlIf(request.status === RequestStatus.WITHDRAWN && !!request.withdrawnAt,
              <>
                <span className="mx-1">•</span>
                Cancelled
                <span className=""> {formatDate(request.withdrawnAt)}</span>
              </>
            )}
            {htmlIf(request.status === RequestStatus.COMPLETED && !!request.completedAt,
              <>
                <span className="mx-1">•</span>
                Completed
                <span className=""> {formatDate(request.completedAt)}</span>
              </>
            )}
            {htmlIf(request.status === RequestStatus.REFUNDED && !!request.refundedAt,
              <>
                <span className="mx-1">•</span>
                Refunded
                <span className=""> {formatDate(request.refundedAt)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const ViewRequestOverview = () => {
    return (
      <div className="p-3 border-bottom">
        <div className="pb-2 border-bottom">
          {RequestDescription(request.description, request.tags)}
          {maybeHtml(request.scope, (scope) => (
            <div className="d-flex align-items-center mt-2">
              <i className="ai-briefcase fs-xxl me-2" />
              {displayScope(scope)}
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-6 mt-2 d-flex align-items-center">
            <i className="ai-tag text-gray-900 fs-xxl me-1" />
            { request.acceptingOffers
            ? `Accepting offers ${displayRequestBudgetRangeUSD(request)}`
            : "Fixed rate"
            }
          </div>
          <div className="col-6 mt-2 d-flex align-items-center">
            {htmlIf(false,
              <>
                <i className="ai-calendar text-gray-900 fs-xxl me-1" />
                {/* Request Length -- Not yet available for project requests */}
              </>
            )}
          </div>
          <div className="col-6 mt-2 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="avatar avatar-24">
                <img className="avatar-img rounded-circle" src={request.customerImageUrl}/>
              </div>
              <div className="ms-1">{request.customerName}</div>
            </div>
          </div>
          <div className="col-6 mt-2 d-flex align-items-center">
            { request.copilot === null
            ? <div className="">
                <i className="ai-user text-gray-900 fs-xxl me-1" />
                Unassigned
              </div>
            : <div className="d-flex align-items-center">
                <div className="avatar avatar-24">
                  <img className="avatar-img rounded-circle" src={request.copilot.imageUrl}/>
                </div>
                <div className="ms-1">{request.copilot.preferredName}</div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const ViewRating = () => (
    <div className="p-3 bg-accent rounded-bottom border-top">

      {htmlIf(!ratingCompletedOnPageLoad, maybeHtml(request.customerRating, (rating) => (
        <div className="alert alert-info p-3">
          Rating submitted. Thank you for your feedback!
        </div>
      )))}
      <h5>{"How would you rate your experience with " + request.copilot?.preferredName + " on this request?"}</h5>
      { request.customerRating
      ? <>
          {ViewSubmittedRating(request.customerRating)}
        </>
      : <>
          <div className="row mb-3">
            <FiveStarRating rating={requestRating} setRating={setRequestRating} starClass={'h2 mb-0 text-primary pe-1'}/>
          </div>
          <RatingModalButton
            requestType={RequestType.PROJECT_REQUEST}
            requestId={request.id}
            copilotName={request.copilot?.preferredName}
            presetOverallRating={requestRating}
            buttonClass="btn btn-primary px-3 t--submit-rating"
            buttonText='Continue'
            requestSetter={setRequest}
          />
        </>
      }

    </div>
  )

  const ViewReceipt = () => {
    return (
      <div className="p-3">
        <h3>Receipt</h3>
        <div className="mt-2 fs-md">
          <div className="row pb-1 g-0 no-gutters border-bottom">
            <div className="col">Subtotal</div>
            <div className="col-auto text-end">{formatUSDWithCents(request.subtotalCents)}</div>
          </div>
          {htmlIf(request.platformFeeCents > 0,
            <div className="row py-1 g-0 no-gutters border-bottom">
              <div className="col">Service Fee</div>
              <div className="col-auto text-end">{formatUSDWithCents(request.platformFeeCents)}</div>
            </div>
          )}
          <div className="row pt-1 g-0 no-gutters fw-bold">
            <div className="col d-flex align-items-center">
              Request Total
              {htmlIf(request.paidAt,
                <span className="ms-2 badge bg-faded-success text-success fs-xs">Paid {formatDate(request.paidAt)}</span>
              )}
              {htmlIf(request.refundedAt,
                <span className="ms-2 badge bg-faded-danger text-danger fs-xs">Refunded {formatDate(request.refundedAt)}</span>
              )}
            </div>
            <div className="col-auto text-end">{formatUSDWithCents(request.subtotalCents + request.platformFeeCents)}</div>
          </div>
        </div>
        {htmlIf(request.customerHasActiveSubscription === false && props.perspective == Perspective.Agent,
          <div className="mt-2 p-2 bg-faded-info rounded-1 text-gray-900 d-flex align-items-center">
            Want to save 50% on your service fees?
            <a href={upgradeMembershipPath(props.perspective)} className="btn btn-link text-info fs-md p-0 py-1 ms-1">Upgrade now</a>
          </div>
        )}
      </div>
    )
  }

  const ViewOffers = () => {
    const pendingOffers = request.offers.filter((offer) => offer.status === RequestOfferStatus.PENDING)

    function handleAcceptOfferClicked(offer: ProjectRequestOffer) {
      setSelectedOffer(offer);
      setModal(Modal.AcceptOfferModal);
    }

    function handleDeclineOfferClicked(offer: ProjectRequestOffer) {
      setSelectedOffer(offer);
      setModal(Modal.DeclineOfferModal);
    }

    function ViewSingleOffer(offer: ProjectRequestOffer, lastOffer: boolean) {
      return (
        <div className={`pb-2 rounded-2 row g-0 no-gutters ${lastOffer ? "" : "border-bottom mb-2"}`}>
          <div className="col-auto me-1">
            <div className="col d-flex align-items-center text-dark">
              <div className="avatar avatar-32">
                <img className="avatar-img rounded-circle" src={offer.copilot.imageUrl}/>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row align-items-center">
              <div className="col d-flex align-items-center">
                <div className="h4 text-gray-700 mb-0">{offer.copilot.preferredName}</div>
              </div>
              <div className="col-auto fs-md">
                {formatUSDWithCents(offer.amountCents)}
              </div>
            </div>
            <div className="mt-1 fs-md">{formatMultiParagraphString(offer.description)}</div>
            <div className="mt-1 fs-sm text-gray-700">
              { offer.lastEditedAt
              ? `${formatDate(offer.lastEditedAt)} (edited)`
              : formatDate(offer.createdAt)
              }
            </div>
            <div className="">
              <button className="btn btn-outline-success mt-1 t--accept-offer" onClick={() => handleAcceptOfferClicked(offer)}>Accept offer</button>
              <button className="btn btn-outline-danger mt-1 ms-2 t--decline-offer" onClick={(() => handleDeclineOfferClicked(offer))}>Decline offer</button>
              <a href={copilotProfileUrl(offer.copilot)} target="_blank" className="btn btn-outline-primary mt-1 ms-2 px-2">
                <i className="ai-external-link me-1" />
                View Profile
              </a>
              <a href={messagesPath(props.perspective, offer.copilot)} className="btn btn-link mt-1 ms-2 px-0">
                Message
              </a>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="p-3">
        <h3>Offers</h3>
        {htmlIf(pendingOffers.length === 0,
          <div>
            It looks like you haven't received any offers from CoPilots just yet — you'll get an email
            as soon as you receive an offer!
          </div>
        )}
        {pendingOffers.map((offer, index) =>
          <div key={offer.id}>
            { offer.status === RequestOfferStatus.PENDING
            ? ViewSingleOffer(offer, index + 1 === pendingOffers.length)
            : null
            }
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <ViewModal />
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={requestsPath(props.perspective)} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            All requests
          </a>
        </div>
      </div>
      <div className="card rounded-2 mt-1">
        {ViewRequestHeader()}
        {ViewRequestOverview()}
        {htmlIf(request.status === RequestStatus.SUBMITTED || request.status === RequestStatus.DECLINED_PENDING,
          <>
            { props.negotiationEnabled && request.acceptingOffers
            ? <ViewOffers />
            : null
            }
          </>
        )}
        {htmlIf(!request.acceptingOffers,
          ViewReceipt()
        )}
        {htmlIf(request.status === RequestStatus.COMPLETED && request.copilot !== null, ViewRating())}
      </div>
    </div>
  )
};


export default RequestDetails;
