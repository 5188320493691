export type Tag =
  { id: number
  , name: string
  , tagType: TagType
  }

// This should be kept in line with the Tag::Type class in tag.rb
export enum TagType
  { TECHNOLOGY = 'technology'
  , SUPPORT = 'support'
  }

export function tagToReactSelectItem(tag: Tag): { value: number, label: string } {
  return { value: tag.id, label: tag.name }
}

// Technically this function could also return `undefined` if no matching tag is found in the allTags array,
// but for our purposes this is unlikely and the extra effort of needing to deal with the optional Tag is not worth the squeeze here.
export function reactSelectItemToTag(item: { value: number, label: string }, allTags: Tag[]): Tag {
  return allTags.find((tag) => tag.id === item.value);
}
