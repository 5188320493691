export type RequestScope =
  { size: Size
  , length: Length
  , experience: Experience
  }

export enum Size
  { SMALL = 'small'
  , MEDIUM = 'medium'
  , ONGOING = 'ongoing'
  }

export function sizeDescription(size: Size) {
  switch (size) {
    case Size.SMALL: return 'Small'
    case Size.MEDIUM: return 'Medium'
    case Size.ONGOING: return 'Ongoing'
  }
}

export enum Length
  { SHORT = 'short'
  , MEDIUM = 'medium'
  , LONG = 'long'
  }

export function lengthDescription(length: Length) {
  switch (length) {
    case Length.SHORT: return '1 to 3 months'
    case Length.MEDIUM: return '3 to 6 months'
    case Length.LONG: return 'More than 6 months'
  }
}

export enum Experience
  { ENTRY = 'entry'
  , INTERMEDIATE = 'intermediate'
  , EXPERT = 'expert'
  }

export function experienceDescription(experience: Experience) {
  switch (experience) {
    case Experience.ENTRY: return 'Entry level'
    case Experience.INTERMEDIATE: return 'Intermediate level'
    case Experience.EXPERT: return 'Expert level'
  }
}

export function displayScope(scope: RequestScope) {
  if (scope.size !== null && scope.length !== null && scope.experience !== null) {
    return `Size: ${sizeDescription(scope.size)} • Length: ${lengthDescription(scope.length)} • Experience: ${experienceDescription(scope.experience)}`
  }
}
